<template>
    <!--<ul :class="{'ms-3':node.item}" ref="list">-->
    <ul  ref="list">
      <!--<TransitionGroup name="list">-->
        <template v-for="item in node.children"  >
            <li v-if="item.item" class="d-flex my-1">
                <button class="btn btn-link btn-sm  p-0 me-1 rounded-0" :disabled="!item.hasNodes" @click="expand(item)" >
                    <i class="bi bi-plus-square-dotted hover" v-if="item.hasNodes && !item.expanded && !item.loading"></i>
                    <i class="bi bi-dash-square-dotted hover" v-if="item.hasNodes && item.expanded && !item.loading"></i>
                    <i class="bi bi-dash"  v-if="!item.hasNodes"></i>
                 
                    <div v-if="item.loading" class="spinner-border" role="status"></div>
                
                </button> 
                <input v-if="isMultiSelect" v-model="item.selected" type="checkbox" class="form-check-input me-2" :indeterminate="!item.selected && item.hasSelected">
                <button class="btn btn-link btn-sm  p-0 text-truncate tree-item d-inline rounded-0" @click="item.nodeClick(item)" :title="item.title">
                    <slot :item="item"></slot>
                </button>
            </li>
            <TreeList :node="item"  v-slot="{item}"><slot :item="item"></slot></TreeList>
        </template>
        <!--</TransitionGroup>-->
    </ul>
</template>

<script setup lang="ts">
import { useAutoAnimate } from 'auto-animate';
import { computed } from 'vue';

const props = defineProps<{
    node: any
}>();

const [list] = useAutoAnimate({
    disrespectUserMotionPreference: true
});

const expand = (item) => {
    item.initChildren();
    item.nodeExpand(item);
}

const isMultiSelect = computed(() => {
    return props.node?.config?.multiSelect;
});
</script>
<style scoped>
    .btn-link{
        text-decoration:none;
    }
    ul {
        list-style-type: none;
    }
    
    .spinner-border{
        width:16px;
        height:16px;
    }
   
    .tree-item:hover{
        background:var(--bs-primary-bg-subtle);
    }
    .hover:hover{
         -webkit-text-stroke: 1px;
    }



</style>